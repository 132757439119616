export const initialState = {
  token: "",
  userStatus: false,
  declarationType: 0,
  hasAnInCome: true,
  realEstate: 0,
  realEstatesArr: [],
  vehicle: 0,
  vehicles: [],
  contribution: 0,
  contribution_sum: "0",
  dependents: 0,
  dependents_sum: "0",
  credit: 0,
  credits: [],
  buy_securities_sum: "0",
  sell_securities_sum: "0",
  registered: false,
  formCells: {
    "056": 0,
    "060": 0,
    "061": 0,
    "062": 0,
    "066": 230_000,
    "067": 0,
    "076": 0,
    "077": 0,
    115: 0,
    116: 0,
    150: 0,
    151: 0,
    152: 0,
    153: 0,
    154: 0,
    155: 0,
    156: 0,
    157: 0,
    158: 0,
    159: 0,
    160: 0,
    161: 0,
    162: 0,
    198: 0,
    250: 0,
    251: 0,
    252: 0,
    253: 0,
    254: 0,
    255: 0,
    256: 0,
    257: 0,
    258: 0,
    259: 0,
    260: 0,
    261: 0,
    262: 0,
    263: 0,
    264: 0,
    265: 0,
    266: 0,
    267: 0,
    268: 0,
    269: 0,
    270: 0,
    271: 0,
    289: 0,
    290: 0,
    291: 0,
    298: 0,
    503: 0,
    906: 0,
  },
  question: {},
  inn: "",
  fullname: "",
  passportNumber: "",
  registeredAddress: "",
  actualResidenceAddress: "",
  registeredStreet: "",
  actualResidenceStreet: "",
  // inn: 11604199601659,
  // fullname: "Копцева Юлия Алексеевна",
  // passportNumber: "ID05027999",
  // registeredAddress: "г. Бишкек",
  // actualResidenceAddress: "г. Бишкек",
  // registeredStreet: "ул. Саадаева 314",
  // actualResidenceStreet: "ул. Саадаева 314",
  taxOffice: {
    districtCode: "",
  },
  localitySelectModal: { open: false, name: "" },
  districtSelectModal: { open: false, name: "" },
  transportSelectModal: { open: false, name: "" },
  zonalCoefficientSelectModal: { open: false, name: "" },
  finalValues: { cell056: "0" },
};
