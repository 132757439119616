import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./PDF-tables.css";
import "./PDF-forms.css";
import "./App.css";

const Main = React.lazy(() => import("pages/Main"));

function App() {
  return (
    <div className="App" id="app" style={{ overflow: "auto" }}>
      <Suspense
        fallback={
          <div className="loading_container flex_box">
            <div className="loading_indicator">
              <svg viewBox="22 22 44 44">
                <circle
                  className="svg_circle"
                  cx="44"
                  cy="44"
                  r="20.2"
                  fill="none"
                  strokeWidth="3.6"></circle>
              </svg>
            </div>
          </div>
        }>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Main />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
